$govuk-assets-path: '~govuk-frontend/govuk/assets/';
@import '~govuk-frontend/govuk/all';
@import '~@ministryofjustice/frontend/moj/components/side-navigation/_side-navigation';

.app-no-border {
  border: none;
}

.app-select-width-20 {
  min-width: 200px;
}

.app-select-width-40 {
  min-width: 400px;
}

.app-table-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.app-table-cell-one-additional {
  width: 50%;
}

.app-table-cell-two-additional {
  width: 50%;
  padding-left: 40%;
  justify-content: flex-start;
}

.moj-search form {
  align-items: flex-end;
  display: flex;
}

.moj-search__button {
  display: inline-block;
  margin-left: 10px;
  position: relative;
  vertical-align: bottom;
  width: auto;
}

.app-padding-left-signin {
  padding-left: 0;
}

.app-padding-top-dashboard {
  padding-top: 90px;
}

.app-no-border {
  border: none;
}

.app-select-width-20 {
  min-width: 200px;
}

.app-select-width-40 {
  min-width: 400px;
}

.app-images-container {
  width: 80%;
}

.app-images-style {
  height: 400px;
}

.app-table-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.app-table-cell-one-additional {
  width: 50%;
}

.app-table-cell-two-additional {
  width: 50%;
  padding-left: 40%;
  justify-content: flex-start;
}

.app-footer-links {
  margin-right: 1em;
}

.focus {
  &:focus {
    color: #0b0c0c;
  }
}